import { FormResponse } from "@/forms/FormResponse";
import { LabsStorageHandler } from "@/storage/storageHandlers/LabsStorageHandler";
import { ISendReportToLabPresenter } from "../controllers/SendReportToLabController";
import {
  WorkOrder,
  WorkOrderReportXlsxResponse
} from "../services/WorkOrderService";
import { SendReportToLabViewModel } from "../vms/SendReportToLabViewModel";
import { FileUtils } from "@/utils/FileUtils";

export class SendReportToLabPresenter implements ISendReportToLabPresenter {
  public constructor(private vm: SendReportToLabViewModel) {}

  public mounted() {
    this.vm.mailAddress.items = LabsStorageHandler.getLabsMailAddresses();

    if (this.vm.mailAddress.items && this.vm.mailAddress.items.length > 0) {
      this.vm.mailAddress.selected.push(this.vm.mailAddress.items[0]);
    }
  }

  public set getReportsResponse(response: FormResponse<WorkOrder[]>) {
    this.vm.tableLoading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.tableItems = response.data;
    }
  }

  public set sendReportsResponse(response: FormResponse<string>) {
    this.vm.requestLoading = response.loading;
    this.vm.requestError = response.error;

    if (!response.loading && !response.error) {
      this.vm.finished = true;
    }
  }

  public set downloadReportXlsxResponse(
    response: FormResponse<WorkOrderReportXlsxResponse>
  ) {
    this.vm.reportXlsxId = response.data.workOrderId;
    this.vm.reportXlsxLoading = response.loading;
    this.vm.reportXlsxError = response.error;

    if (response.success) {
      const data = response.data;

      FileUtils.downloadBase64AsFile(
        data.fileName ?? "report.xlsx",
        data.content ?? "",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
  }

  public get data() {
    return {
      mailAddress: this.vm.mailAddress.selected,
      subject: this.vm.subject,
      text: this.vm.text,
      workOrderIds: this.vm.tableItems.map(el => el.id),
      xlsxReportOptions: this.vm.xlsxReportOptions.selected,
      testSend: this.vm.sendTest.value
    };
  }

  public removeTableItem(id: string) {
    const itemIndex = this.vm.tableItems.findIndex(el => el.id === id);

    if (itemIndex >= 0) {
      this.vm.tableItems.splice(itemIndex, 1);
    }
  }
}
