

































import Vue from "vue";
import Component from "vue-class-component";

import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

@Component<IconButton>({
  props: {
    tooltipWidth: {
      type: Number,
      default: 100
    },
    tooltipText: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 22
    }
  }
})
export default class IconButton extends Vue {
  protected tooltipVisible: boolean = false;

  protected get buttonIcon() {
    return !this.$props.error ? this.$props.icon : "replay";
  }

  protected get buttonColor() {
    return !this.$props.error ? this.$props.color : "error";
  }

  protected get errorText() {
    return `'${this.$props.tooltipText}' gescheitert mit: ${this.$props.error}`;
  }

  protected click(skipTooltip: boolean = false) {
    if (skipTooltip || this.$props.tooltipWidth === 0) {
      this.tooltipVisible = false;
      this.$emit("click");
      return;
    }
    this.tooltipVisible = true;
  }

  protected hover() {
    if (EnvironmentUtils.isMobile() || this.$props.tooltipWidth === 0) {
      return;
    }
    this.tooltipVisible = true;
  }

  protected unhover() {
    if (EnvironmentUtils.isMobile()) {
      return;
    }
    this.tooltipVisible = false;
  }
}
