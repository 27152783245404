import {
  IWorkOrderService,
  WorkOrder,
  Pagination,
  WorkOrderReportXlsxResponse
} from "./WorkOrderService";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler";
import { OrderByClause } from "@/gateways/graphql/GraphQLConnection";
import { WorkOrderStorage } from "../utils/WorkOrderStorage";
import { StorageHolder } from "@/storage/StorageHolder";
import { Dictionary } from "vue-router/types/router";

interface ILocalWorkOrderService {
  getAllLocalWorkOrders(): Promise<WorkOrder[]>;
}

export class WorkOrderLocalService
  implements IWorkOrderService, ILocalWorkOrderService {
  private workOrderStorage = StorageHolder.WorkOrderStorage;
  private plantStorage = StorageHolder.PlantStorage;

  public get timestamp() {
    return this.workOrderStorage.timestamp;
  }

  public set workOrders(workOrders: WorkOrder[]) {
    this.workOrderStorage.storeWorkOrders(
      workOrders.filter(
        wo => wo.processingState === "OPEN" || wo.processingState === "REJECTED"
      )
    );
    this.workOrderStorage.save();
  }

  public save() {
    this.workOrderStorage.save();
    this.plantStorage.save();
  }

  public async getAllWorkOrders(): Promise<WorkOrder[]> {
    return Promise.resolve(this.workOrderStorage.workOrders);
  }

  public getWorkOrderProcessingStates(ids: string[]): Promise<any> {
    const foundWorkOrders = this.workOrderStorage.workOrders.filter(wo =>
      ids.some(el => el === wo.id)
    );

    return Promise.resolve(
      foundWorkOrders.map(wo => ({
        id: wo.id,
        processingState: wo.processingState
      }))
    );
  }

  public async getAllOpenWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    return this.getWorkOrdersByState(search, ["OPEN", "REJECTED"], pagination);
  }

  public async getAllLocalWorkOrders(): Promise<WorkOrder[]> {
    return this.workOrderStorage.workOrders;
  }

  public async getAllToCheckWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    return this.getWorkOrdersByState(search, ["TO_CHECK"], pagination);
  }

  public async getAllFinishedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    return this.getWorkOrdersByState(search, ["FINISHED"], pagination);
  }

  public async getAllToInvoiceWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    return this.getWorkOrdersByState(search, ["TO_INVOICE"], pagination);
  }

  public async getAllArchivedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    return this.getWorkOrdersByState(search, ["ARCHIVED"], pagination);
  }

  public async getAllOpenWorkOrdersForServiceEngineer(
    serviceEngineerId: string
  ): Promise<WorkOrder[]> {
    throw new Error("Not available in offline mode");
  }

  public async getAllWorkOrdersPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    throw new Error("Not available in offline mode");
  }

  public async getAllFinishedWorkOrdersWithoutLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    throw new Error("Not available in offline mode");
  }

  public async getAllFinishedWorkOrdersWithLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    throw new Error("Not available in offline mode");
  }

  public async sendReportsToLab(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async sendReportsToBh(workOrderIds: string[]): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public async getAllWorkOrdersWithReport(): Promise<WorkOrder[]> {
    throw new Error("Not available in offline mode");
  }

  public async getAllWorkOrdersWithReportPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    throw new Error("Not available in offline mode");
  }

  public async getWorkOrderById(workOrderId: string): Promise<WorkOrder> {
    return Promise.resolve(this.workOrderStorage.getWorkOrderById(workOrderId));
  }

  public async getWorkOrdersInDateRange(
    serviceEngineerId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<WorkOrder[]> {
    const workOrders = this.workOrderStorage.workOrders.filter(
      wo =>
        wo.plannedDate >= new Date(dateFrom) &&
        wo.plannedDate < new Date(dateTo)
    );

    return Promise.resolve(workOrders);
  }

  public async upsertWorkOrder(
    id: string,
    processingState: string,
    serviceEngineerId: string,
    usedTime: number,
    tasksDone: string[],
    materials: string,
    defects: string[],
    comments: string[],
    cannotTakeSignature: boolean,
    signature: string,
    reportData: any,
    operatorData: any,
    plantData: any,
    price: number | null,
    hk: number,
    updatedAt: string | null
  ): Promise<string> {
    this.workOrderStorage.updateWorkOrder(
      id,
      processingState,
      serviceEngineerId,
      usedTime,
      tasksDone,
      materials,
      defects,
      comments,
      cannotTakeSignature,
      signature,
      reportData,
      operatorData,
      plantData,
      price,
      hk
    );

    return Promise.resolve(id);
  }

  public async acceptWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[]
  ): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async rejectWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[],
    reason: string
  ): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async getPlantParameters(plantId: string): Promise<PlantParameter[]> {
    return Promise.resolve(
      this.plantStorage
        .getPlantById(plantId)
        .parameters.map(p => ({ id: p.parameterId, value: p.value }))
    );
  }

  public async uploadLabReport(
    workOrderId: string,
    file: File
  ): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async setWorkOrderToCheck(workOrderId: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async setWorkOrderFinished(workOrderId: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async invoiceWorkOrder(workOrderId: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async archiveWorkOrder(workOrderId: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async cancelWorkOrder(
    workOrderId: string,
    cancellationReason: string
  ): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async downloadReportXlsx(
    workOrderId?: string
  ): Promise<WorkOrderReportXlsxResponse> {
    throw new Error("Not available in offline mode");
  }

  public async sendWorkOrders(workOrderId?: string): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public getToSyncWorkOrders() {
    return this.workOrderStorage.getToSyncWorkOrders();
  }

  public setWorkOrderChanged(id: string, changed: boolean) {
    this.workOrderStorage.setWorkOrderChanged(id, changed);
  }

  private async getWorkOrdersByState(
    search: string,
    states: string[],
    pagination: Pagination
  ): Promise<PaginatedList<WorkOrder>> {
    const filteredItems = this.workOrderStorage.workOrders.filter(
      wo =>
        states.includes(wo.processingState) &&
        (!search || this.buildSearchString(wo).includes(search.toLowerCase()))
    );

    const slicedItems = filteredItems.slice(
      pagination.itemsPerPage * (pagination.pageNumber - 1),
      pagination.itemsPerPage * pagination.pageNumber
    );

    return Promise.resolve({
      items: slicedItems,
      totalCount: filteredItems.length
    });
  }

  private buildSearchString(wo: WorkOrder): string {
    return `${wo.addressStreet} ${wo.addressCity} ${wo.addressZip} ${
      wo.plantIndex
    } ${(wo as any).operatorName}`.toLowerCase();
  }
}
