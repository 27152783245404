import { FormResponse } from "@/forms/FormResponse";
import { Checkbox, MultiSelect, TableHeader } from "@/forms/ViewModelFormTypes";
import { WorkOrderReportXlsxResponse } from "../services/WorkOrderService";

export class SendReportToLabViewModel {
  public reportXlsxId = "";
  public reportXlsxLoading = false;
  public reportXlsxError = "";

  public mailAddress: { selected: string[]; items: string[] } = {
    items: [],
    selected: []
  };
  public subject = "Prüfberichte";
  public text =
    "Sehr geehrte Damen und Herren,\r\n\r\n" +
    "im Anhang übermitteln wir Ihnen die Prüfberichte zu Ihrer weiteren Verwendung.";
  public xlsxReportOptions: MultiSelect = {
    label: "XLSX-Bericht erstellen für...",
    items: [
      { text: "Prüfberichte mit gültiger Probenahme", value: "with-sample" },
      {
        text:
          "Prüfberichte mit unmöglicher Probenahme (Probenahme nicht möglich)",
        value: "without-sample"
      },
      { text: "Berichte ohne Anlage", value: "without-plant" }
    ],
    selected: ["with-sample"],
    error: ""
  };

  public sendTest: Checkbox = {
    value: false,
    error: "",
    label: "Testversand"
  };

  public tableLoading = false;
  public tableHeaders: TableHeader[] = [
    { text: "Anlagennummer", value: "plantIndex", width: "140px" },
    {
      text: "Kalenderwoche",
      value: "weekNumber",
      width: "140px",
      sortable: false
    },
    { text: "Datum", value: "plannedDate" },
    {
      text: "Dateigröße",
      value: "fileSize"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "200px"
    }
  ];
  public tableItems: any[] = [];

  public requestLoading = false;
  public requestError = "";
  public finished = false;
}
